import React, { useState, useEffect, Fragment } from 'react';
import ReactApexChart from 'react-apexcharts';
import { TableNoData } from 'src/components/table';

const ApexChart = ({ isLine, isBot, isBar, isWaBar, chartdata }) => {
  // Define the initial state for the line chart
  const [lineBotData, setLineBotData] = useState({
    series: [],
    options: {
      chart: {
        height: 350,
        type: 'line',
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'straight',
      },
      title: {
        text: '',
        align: 'left',
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'],
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: [], // Placeholder for dynamic dates
      },
    },
  });

  // Define the initial state for the first bar chart
  const [barChartData, setBarChartData] = useState({
    series: [
      {
        name: 'Messages',
        data: [], // Placeholder for message data
      },
    ],
    options: {
      chart: {
        type: 'bar',
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded',
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent'],
      },
      title: {
        text: '',
        align: 'left',
      },
      xaxis: {
        categories: [], // Placeholder for message categories
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val + ' messages';
          },
        },
      },
    },
  });

  // Define the initial state for the second bar chart
  const [whatsAppBarChartData, setWhatsAppBarChartData] = useState({
    series: [
      {
        name: 'WhatsApp Template',
        data: [],
      },
      {
        name: 'WhatsApp Session',
        data: [],
      },
    ],
    options: {
      chart: {
        type: 'bar',
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded',
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent'],
      },
      title: {
        text: '',
        align: 'left',
      },
      xaxis: {
        categories: [],
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val + ' messages';
          },
        },
      },
    },
  });

  const whatsAppData = [
    {
      date: '27-july-2024',
      report: {
        whatsappTemplate: 10,
        whatsappSession: 25,
      },
    },
    {
      date: '29-july-2024',
      report: {
        whatsappTemplate: 11,
        whatsappSession: 26,
      },
    },
  ];

  const whatsappDates = whatsAppData?.map((item) => item.date);
  const whatsappTemplateData = whatsAppData?.map((item) => item.report.whatsappTemplate);
  const whatsappSessionData = whatsAppData?.map((item) => item.report.whatsappSession);

  useEffect(() => {
    if (isLine && chartdata) {
      const dates = chartdata?.data?.map((item) => item?.date);
      const counts = chartdata.data?.map((item) => item?.count);
      // console.log(counts,"countss");
      setLineBotData((prevData) => ({
        ...prevData,
        series: [
          {
            name: 'Conversations',
            data: counts,
          },
        ],
        options: {
          ...prevData.options,
          xaxis: {
            ...prevData.options.xaxis,
            categories: dates,
          },
        },
      }));
    } else if (isBot) {
      const dates = chartdata[0]?.analytics?.map((item) => {
        const date = new Date(item?.date);
        return date.toISOString().split('T')[0];
      });
      // console.log(dates, 'datess', isBot);

      const seriesData = chartdata?.map((bot) => ({
        name: bot?.bot_name,
        data: bot?.analytics?.map((entry) => entry?.count),
      }));
      // console.log(seriesData,"xyz")
      setLineBotData((prevData) => ({
        ...prevData,
        series: seriesData,
        options: {
          ...prevData.options,
          xaxis: {
            ...prevData.options.xaxis,
            categories: dates,
          },
        },
      }));
    } else if (isBar && chartdata) {
      setBarChartData((prevData) => ({
        ...prevData,
        series: [
          {
            name: 'Messages',
            data: [chartdata?.sent, chartdata?.delivered, chartdata?.read, chartdata?.failed],
          },
        ],
        options: {
          ...prevData.options,
          xaxis: {
            ...prevData.options.xaxis,
            categories: ['Sent', 'Delivered', 'Read', 'Failed'],
          },
        },
      }));
    } else if (isWaBar && chartdata) {
      setWhatsAppBarChartData((prevData) => ({
        ...prevData,
        series: [
          {
            name: 'WhatsApp Template',
            data: whatsappTemplateData,
          },
          {
            name: 'WhatsApp Session',
            data: whatsappSessionData,
          },
        ],
        options: {
          ...prevData.options,
          xaxis: {
            ...prevData.options.xaxis,
            categories: whatsappDates,
          },
        },
      }));
    } else {
      setLineBotData((prevData) => ({
        ...prevData,
        series: [],
        options: {
          ...prevData.options,
          title: { text: 'No Data Available' },
        },
      }));

      setBarChartData((prevData) => ({
        ...prevData,
        series: [],
        options: {
          ...prevData.options,
          title: { text: 'No Data Available' },
        },
      }));

      setBarChartData((prevData) => ({
        ...prevData,
        series: [],
      }));

      setWhatsAppBarChartData((prevData) => ({
        ...prevData,
        series: [
          {
            name: 'WhatsApp Template',
            data: whatsappTemplateData,
          },
          {
            name: 'WhatsApp Session',
            data: whatsappSessionData,
          },
        ],
        options: {
          ...prevData.options,
          xaxis: {
            ...prevData.options.xaxis,
            categories: whatsappDates,
          },
        },
        options: {
          ...prevData.options,
          xaxis: {
            ...prevData.options.xaxis,
            categories: whatsappDates,
          },
        },
      }));
    }
  }, [isLine, isBot, isBar, isWaBar, chartdata]);
  return (
    <div>
      {/* {(isLine && lineBotData?.series?.length) || (isBot && lineBotData?.series) ? ( */}
      {(isLine && chartdata?.data?.length) || (isBot && chartdata?.length) ? (
        <div id="line-chart">
          <ReactApexChart options={lineBotData?.options} series={lineBotData?.series} type="line" height={350} />
          {/* {console.log('Hii isLine', chartdata, isBot)} */}
        </div>
      ) : isBar && barChartData?.series?.[0]?.data.some((value) => value !== 0) ? (
        <div id="bar-chart">
          <ReactApexChart options={barChartData?.options} series={barChartData?.series} type="bar" height={350} />
          {/* {console.log('Hii isBar', barChartData?.series)} */}
        </div>
      ) : isWaBar && whatsAppBarChartData?.series?.length ? (
        <div id="whatsapp-bar-chart">
          <ReactApexChart
            options={whatsAppBarChartData?.options}
            series={whatsAppBarChartData?.series}
            type="bar"
            height={350}
          />{' '}
          {/* {console.log('Hii Iswabar', whatsAppBarChartData?.series?.length)} */}
        </div>
      ) : (
        <div style={{ display: 'grid', justifyContent: 'center' }}>
          <TableNoData
            isNotFound={isBot || isLine || isBar || isWaBar}
            titleText={`No Data Available`}
            description={`You have no data`}
          />{' '}
          {/* {console.log('Hii No data')} */}
        </div>
      )}
    </div>
  );
};

export default ApexChart;
