import React, { useEffect, useState } from 'react';
import {
  Box,
  Tab,
  Tabs,
  Card,
  Table,
  Switch,
  Button,
  Tooltip,
  Divider,
  TableBody,
  Container,
  IconButton,
  TableContainer,
  TablePagination,
  FormControlLabel,
  CircularProgress,
  FormHelperText,
  Skeleton,
  TableRow,
  TableCell,
} from '@mui/material';
import { TableEmptyRows, TableHeadCustom, TableNoData } from 'src/components/table';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import './Failedmessages.css';
import * as Yup from 'yup';
import { DatePicker } from 'antd';
import axios from 'src/utils/axios';
import { useSnackbar } from 'notistack';
import { ApiEndpoints } from 'src/config';
import { useForm, Controller } from 'react-hook-form';
const { RangePicker } = DatePicker;
import { yupResolver } from '@hookform/resolvers/yup';
import Page from 'src/components/Page';
import useTabs from 'src/hooks/useTabs';
import { emptyRows } from 'src/hooks/useTable';
import Scrollbar from 'src/components/Scrollbar';
import useTable from 'src/hooks/useTable';
import FailedMessageRow from 'src/sections/@dashboard/Analytics/list/FailedMessageRow';
import FailedMessageSearch from 'src/sections/@dashboard/Analytics/list/FailedMessageSearch';
import dayjs from 'dayjs';

const TABLE_HEAD = [
  { id: 'id', label: 'Message Id', align: 'center' },
  { id: 'name', label: 'From', align: 'center' },
  { id: 'message', label: 'Message Content', align: 'center' },
  { id: 'to', label: 'To', align: 'center' },
  { id: 'dateTime', label: 'Date Time', align: 'center' },
  { id: 'remarks', label: 'Remarks', align: 'center' },
];

export default function WaFailedMessages() {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    setRowsPerPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();
  const defaultValues = {};
  const NewUserSchema = Yup.object().shape({});
  const [departments, setDepartments] = useState([]);
  const [filterName, setFilterName] = useState('');
  const [dataFlags, setDataFlags] = useState(false);
  const [failedMessage, setFailedMessage] = useState([]);
  const { currentTab: filterStatus, onChangeTab: onChangeFilterStatus } = useTabs('all');
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const currentYear = dayjs().year();
  const dateFormat = 'YYYY-MM-DD';

  const startDate = dayjs(`${currentYear}-01-01`, dateFormat);
  const currentDate = dayjs();
  const [dates, setDates] = useState([startDate, currentDate]);
  // console.log(dayjs(dates[0]))

  const parsedDates = dates?.map((date) => {
    return dayjs(date).toISOString();
  });
  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });
  const handleFilterName = (filterName) => {
    setFilterName(filterName);
    setPage(1);
  };
  const denseHeight = dense ? 52 : 72;

  const isNotFound = !!filterStatus;

  const STATUS_OPTIONS = ['all'];
  const [loading, setLoading] = useState(false);
  const {
    reset,
    watch,
    control,
    handleSubmit,
    formState: { isSubmitting, errors },
    getValues,
    setValue,
  } = methods;
  const formValues = getValues();
  const { enqueueSnackbar } = useSnackbar();
  const getAgentsDepartments = async () => {
    try {
      const response = await axios.get(ApiEndpoints.DEPARTMENTS);
      const departments = response.data.data;
      // console.log(departments);
      setDepartments(departments);
    } catch (error) {
      console.error('Failed to fetch departments:', error);
      enqueueSnackbar('Failed to fetch departments!', { variant: 'error' });
    }
  };
  const getFailedMessages = async (startDate, endDate, departmentId) => {
    try {
      const response = await axios.get(
        `${ApiEndpoints.FAILEDMESSAGES}?start_date=${startDate}&end_date=${endDate}&department=${departmentId}&page=${
          page + 1
        }&size=${rowsPerPage}`
      );
      if (response?.data?.status === 200) {
        const failedmessageRes = response?.data?.data;
        // console.log(failedmessageRes)
        setFailedMessage(failedmessageRes);
        setTotalPages(failedmessageRes?.total_pages - 1);
        setCurrentPage(failedmessageRes?.current_page);
        setTotalCount(failedmessageRes?.total_elements);
      }
    } catch (error) {
      console.error('Failed to fetch messages:', error);
      enqueueSnackbar('Failed to fetch messages!', { variant: 'error' });
    }
  };

  useEffect(() => {
    getAgentsDepartments();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleDepartmentChange = (event) => {
    setValue('department', event.target.value);
    if (event.target.value && parsedDates?.length > 0) {
      setDataFlags(true);

      getFailedMessages(parsedDates?.[0], parsedDates?.[1], event.target.value);
    }
  };
  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    if (totalPages === page + 1) {
      setPage(0);
    }
  };
  const clearData = () => {
    setValue('department', '');
    setFailedMessage([]);
    setDataFlags(false);
  };
  return (
    <section className="failed-message">
      <Page title="Failed Messages" style={{ marginLeft: '20px', marginRight: '20px' }}>
        <section>
          <div className="analytics-headd">
            <div className="chat-range">
              <RangePicker
                className="date-css"
                defaultValue={[startDate, currentDate]}
                onChange={(values) => {
                  clearData();
                  setDates(
                    values?.map((item) => {
                      return dayjs(item).format('YYYY-MM-DD');
                    })
                  );
                }}
              />
            </div>
            {parsedDates?.length > 0 ? (
              <div style={{ marginTop: '8px' }}>
                <FormControl sx={{ width: 300 }}>
                  <InputLabel id="department-multi-select-label">Department</InputLabel>
                  <Select
                    sx={{ background: 'white', boxShadow: '1px 2px 3px #ddd' }}
                    labelId="department-multi-select-label"
                    id="department-multi-select"
                    name="department"
                    value={formValues?.department}
                    onChange={(e) => handleDepartmentChange(e)}
                    input={<OutlinedInput label="Department" />}
                  >
                    {departments?.map((department) => (
                      <MenuItem key={department.id} value={department.id}>
                        {department.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText error={Boolean(errors?.department?.message)}>
                    {errors?.department?.message}
                  </FormHelperText>
                </FormControl>
              </div>
            ) : (
              <></>
            )}
          </div>
        </section>
        {dataFlags && parsedDates?.length > 0 ? (
          <Card>
            <Tabs
              allowScrollButtonsMobile
              variant="scrollable"
              scrollButtons="auto"
              value={filterStatus}
              onChange={onChangeFilterStatus}
              sx={{ px: 2, bgcolor: 'background.neutral' }}
            >
              {STATUS_OPTIONS?.map((tab) => (
                <Tab disableRipple key={tab} label={tab} value={tab} />
              ))}
            </Tabs>
            <Divider />

            <FailedMessageSearch filterName={filterName} onFilterName={handleFilterName} />

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
                <Table size={dense ? 'small' : 'medium'}>
                  <TableHeadCustom
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={departments?.length}
                    numSelected={selected?.length}
                    onSort={onSort}
                    onSelectAllRows={(checked) =>
                      onSelectAllRows(
                        checked,
                        departments?.map((row) => row.id)
                      )
                    }
                  />
                  <TableBody>
                    {loading ? (
                      <TableSkeleton rowsPerPage={rowsPerPage} TABLE_HEAD={TABLE_HEAD} />
                    ) : (
                      <>
                        {failedMessage?.length > 0 &&
                          failedMessage?.map((row) => (
                            <FailedMessageRow
                              key={row.message_id}
                              row={row}
                              selected={selected.includes(row.message_id)}
                            />
                          ))}
                        <TableEmptyRows height={denseHeight} emptyRows={emptyRows(page, rowsPerPage, totalCount)} />

                        <TableNoData
                          isNotFound={isNotFound}
                          titleText={`No Failed message found`}
                          description={`You have no failed messages to view in ${filterStatus} tab`}
                        />
                      </>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>

            <Box sx={{ position: 'relative', maxHeight: '54px' }}>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={totalCount}
                rowsPerPage={rowsPerPage}
                page={currentPage}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Box>
          </Card>
        ) : (
          <></>
        )}
      </Page>
    </section>
  );
}
