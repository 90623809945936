import React, { useEffect } from 'react';
import { Box, Button, Divider } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import './whatsapptemplate.css';
import qs from 'qs';
import { useNavigate } from 'react-router';
import { PATH_APP } from 'src/routes/paths';
import {
  Reply as ReplyIcon,
  Launch as LaunchIcon,
  Phone as PhoneIcon,
} from '@mui/icons-material';
import FormatListBulletedSharpIcon from '@mui/icons-material/FormatListBulletedSharp';
import InfiniteScroll from 'react-infinite-scroll-component';

export const ReturnParsedValue = (body, params) => {
  const parts = body?.split(/(\{\{\d+\}\})/);

  const renderedParts = parts?.map((part, index) => {
    const match = part.match(/\{\{(\d+)\}\}/);
    if (match) {
      const paramIndex = parseInt(match[1], 10) - 1;
      return (
        <React.Fragment key={index}>
          <span className="abhi-style">{params[paramIndex] || ''}</span>
        </React.Fragment>
      );
    }
    return part;
  });

  return <>{renderedParts}</>;
};

const WhatsAppTemplateHome_Library = ({
  whatsappDataLibrary,
  loader,
  getWhatsappTemplateLibrary,
  endPagination,
  code,
}) => {
  const navigate = useNavigate();

  const handleRedirect = (item) => {
    const queryString = qs.stringify(item, { encode: true });
    navigate(`${PATH_APP.management.waTemplatesNew}?${queryString}`);
  };

  return (
    <>
      <InfiniteScroll
        dataLength={whatsappDataLibrary.length}
        next={() => getWhatsappTemplateLibrary(code?.cursors?.after)}
        hasMore={!endPagination}
        loader={(
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <CircularProgress />
          </Box>
        )}
        endMessage={<p style={{ textAlign: 'center' }}>No more templates to load</p>}
        scrollThreshold="200px"
        style={{overflow: 'none'}}
      >
        <div className="four-grid" style={{ margin: '1rem 0' }}>
          {whatsappDataLibrary?.map((item) => (
            <div className="second-box" key={item?.id}>
              <div className="second-box-header">
                <div className="first-div">
                  <p className="basic-para">{item?.name}</p>
                </div>
                <div className="second-div">
                  <Button variant="contained" onClick={() => handleRedirect(item)}>
                    Use
                  </Button>
                </div>
              </div>
              <div className="text-body">
                <div className="body-parent">
                  <div className="card-body">
                    {item?.header && <h4>{item?.header}</h4>}
                    <p>{ReturnParsedValue(item?.body, item?.body_params)}</p>
                    {item?.footer && <p className="experience-p">{item?.footer}</p>}
                    {item?.buttons && (
                      <div className="action">
                        {item?.buttons?.length <= 3 ? (
                          item?.buttons?.map((button, index) => (
                            <p key={index}>
                              <span>
                                <Divider sx={{ mb: 0.5 }} />
                                {button?.type === 'QUICK_REPLY' ? <ReplyIcon fontSize='12px' /> :
                                  button?.type === 'URL' ? <LaunchIcon fontSize='12px' /> :
                                    button?.type === 'PHONE_NUMBER' ? <PhoneIcon fontSize='12px' /> : ''}
                              </span>
                              {button?.text}
                            </p>
                          ))
                        ) : (
                          <>
                            {item?.buttons?.slice(0, 2)?.map((button, index) => (
                              <p key={index}>
                                <Divider sx={{ mb: 1 }} />
                                <span>
                                  {button?.type === 'QUICK_REPLY' ? <ReplyIcon fontSize='12px' /> :
                                    button?.type === 'URL' ? <LaunchIcon fontSize='12px' /> :
                                      button?.type === 'PHONE_NUMBER' ? <PhoneIcon fontSize='12px' /> : ''}
                                </span>
                                {button?.text}
                              </p>
                            ))}
                            <p>
                              <span>
                                <FormatListBulletedSharpIcon fontSize='12px' />
                              </span>
                              Explore more
                            </p>
                          </>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="body-footer">
                <span className="body-en">{item?.language}</span>
              </div>
            </div>
          ))}
        </div>
      </InfiniteScroll>
    </>
  );
};

export default WhatsAppTemplateHome_Library;
